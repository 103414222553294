/** @jsx jsx */

import { ClassNames, jsx } from '@emotion/react';
import ButtonLink from 'components/ButtonLink';
import useLocalizationContext from 'hooks/useLocalizationContext';
import Quote from 'icons/Quote';
import { useIntl } from 'react-intl';
import { routeUrl } from 'routesBuilder';
import { PersonalReference } from 'types/Reference';

export interface ReferenceCardProps {
  reference: PersonalReference;
}

const ReferenceCard: React.FC<ReferenceCardProps> = ({ reference }) => {
  const intl = useIntl();
  const { locale, region } = useLocalizationContext();
  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{
            '@media (min-width: 600px)': {
              alignSelf: 'center',
              maxWidth: 950,
              padding: theme.spacing(4),
              width: '100%',
            },
            '@media (min-width: 960px)': {
              flexDirection: 'row',
            },
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2, 1, 4, 2),
            position: 'relative',
          }}
        >
          <div
            css={{
              '& > svg': { height: 40, left: 0, position: 'absolute', top: theme.spacing(3), width: 40 },
              '@media (min-width: 600px)': {
                '& > svg': {
                  left: 8,
                },
              },
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Quote />
            <div
              css={{
                ...theme.typography.caption,
                '@media (min-width: 600px)': {
                  maxWidth: 550,
                },
                fontSize: 10,
                paddingLeft: theme.spacing(4),
              }}
            >
              {reference.quote}
            </div>
            <div css={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', padding: theme.spacing(1) }}>
              <div
                css={{
                  '@media(min-width: 425px)': { fontSize: 14 },
                  '@media(min-width: 600px)': {
                    fontSize: 18,
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: 11,
                }}
              >
                <div>{reference.personName}</div>
                <span css={{ ...theme.typography.caption }}>{reference.personJob}</span>
              </div>
              <div
                css={{
                  '& > img': { display: 'flex', maxHeight: '100%', maxWidth: '100%', width: 'auto' },
                  '@media(min-width: 600px)': { width: 120 },
                  marginLeft: theme.spacing(1),
                  width: 80,
                }}
              >
                <img src={reference.personAvatar} alt='portret' css={{ borderRadius: 100 }} />
              </div>
            </div>
          </div>
          <ButtonLink
            to={routeUrl('reference', { locale, params: { locale: locale, region: region } })}
            css={{ '@media(min-width: 960px)': { alignSelf: 'flex-start', flexShrink: 0 }, alignSelf: 'center' }}
          >
            {intl.formatMessage({
              defaultMessage: 'Další reference',
              id: 'reference.nextref',
            })}
          </ButtonLink>
        </div>
      )}
    </ClassNames>
  );
};

export default ReferenceCard;
