/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import ButtonIcon from 'components/ButtonIcon';
import RightArrow from 'icons/RightArrow';
import React from 'react';

const pipe = (...fns: any) => (v: number) => fns.reduce((r: any, fn: any) => fn(r), v);

export interface ImageListCarouselProps {
  autoSlide?: boolean;
  autoSlideInterval?: number;
  cardCount?: number;
  children: React.ReactNode;
  className?: string;
}

const ImageListCarousel: React.FC<ImageListCarouselProps> = ({
  autoSlide = false,
  autoSlideInterval = 4000,
  children,
  className,
  cardCount = 4,
}) => {
  const cntRef = React.useRef<any>();

  const [chRefs, nextChildren, childrenCount] = React.useMemo(() => {
    const rfs = React.Children.map(children, () => React.createRef());
    return [
      rfs,
      // @ts-ignore next-line
      React.Children.map(children, (ch, idx) => React.cloneElement(ch, { ref: rfs[idx] })),
      React.Children.count(children),
    ];
  }, [children]);

  const [{ disabledLeft, disabledRight, disp }, setDisp] = React.useState({
    disabledLeft: true,
    disabledRight: true,
    disp: 0,
  });

  const move = React.useCallback(
    (pDisp, dir) => {
      const cntWidth = cntRef.current.offsetWidth;
      const maxLeft = cntRef.current.scrollWidth - cntWidth;
      const nextDisp = pipe(
        (d: number) => (d < 0 ? 0 : d),
        (d: number) => (d >= childrenCount ? childrenCount - 1 : d),
      )(pDisp + dir);

      // @ts-ignore next-line
      const delta = chRefs[nextDisp].current.offsetWidth * dir;

      const left = pipe(
        (l: number) => (l < 0 ? 0 : l),
        (l: number) => (l >= maxLeft ? maxLeft : l),
      )(cntRef.current.scrollLeft + delta);

      const dl = { disabledLeft: left === 0 };
      const dr = { disabledRight: left === maxLeft };

      cntRef.current.scroll({ behavior: 'smooth', left, top: 0 });
      setDisp((pd) => ({ ...pd, disp: nextDisp, ...dl, ...dr }));
    },
    [chRefs, childrenCount],
  );

  const handleMove = React.useCallback((dir) => () => move(disp, dir), [disp, move]);

  React.useEffect(() => {
    const tm = setTimeout(() => {
      move(0, 0);
    }, 800);
    return () => {
      clearTimeout(tm);
    };
  }, [move]);

  React.useEffect(() => {
    if (autoSlide) {
      const timer = setTimeout(
        () => (disp !== childrenCount - 1 ? handleMove(1)() : move(disp, -disp)),
        autoSlideInterval,
      );
      return () => clearTimeout(timer);
    }
    return undefined;
  });

  return (
    <ClassNames>
      {({ css, cx, theme }) => (
        <div
          css={{
            '& svg': {
              '@media(min-width: 960px)': {
                display: 'block',
              },
              display: 'none',
            },
            alignItems: 'stretch',
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <ButtonIcon
            onClick={handleMove(-1)}
            disabled={disabledLeft}
            css={{
              '& > svg': {
                fill: disabledLeft ? theme.palette.divider : theme.palette.text.secondary,
                transform: 'rotate(180deg)',
              },
            }}
          >
            <RightArrow />
          </ButtonIcon>
          <div
            css={cx(
              css({
                alignContent: 'center',
                alignItems: 'stretch',
                display: 'flex',
                flexDirection: 'row',
                width: 'calc(100% - 80px)',
              }),
              className,
            )}
          >
            <div
              ref={cntRef}
              css={{
                '& > div': {
                  flexBasis: `${100 / cardCount}%`,
                  flexGrow: 1,
                  flexShrink: 0,
                },
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                display: 'flex',
                flexDirection: 'row',
                overflowX: 'auto',
                width: '100%',
              }}
            >
              {nextChildren}
            </div>
          </div>
          <ButtonIcon
            onClick={handleMove(1)}
            disabled={disabledRight}
            css={{
              '& > svg': {
                fill: disabledRight ? theme.palette.divider : theme.palette.text.secondary,
              },
            }}
          >
            <RightArrow />
          </ButtonIcon>
        </div>
      )}
    </ClassNames>
  );
};

export default ImageListCarousel;
