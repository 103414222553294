/** @jsx jsx */
import { jsx } from '@emotion/react';
import CommercialLine from 'components/CommercialLine';
import Page from 'components/Page';
import MainLayout from 'layouts/MainLayout';
import React from 'react';
import { HomepageData } from 'types/HomepageData';
import { SupportedLocale } from 'types/Supported';
import Certifications from '../../../components/Certifications/Certifications';
import HomePageCloudApp from './HomePageCloudApp';
import HomePageContactForm from './HomePageContactForm';
import HomePageIntroductionDay from './HomePageIntroductionDay';
import HomePageReference from './HomePageReference';
import HomePageSupportApps from './HomePageSupportApps';
import HomePageVideo from './HomePageVideo';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HomePageContentDayProps extends React.HTMLAttributes<HTMLDivElement> {
  data: HomepageData;
  locale: SupportedLocale;
}

const HomePageContentDay: React.FC<HomePageContentDayProps> = ({ data, locale }) => {
  const { advantages, video, layout, applications = [], certifications = [], references, personalReference } = data;
  return (
    <MainLayout {...layout} locale={locale}>
      <Page pageTitle={data.title} css={{ width: '100%' }}>
        <HomePageIntroductionDay />
        <CommercialLine />
        <HomePageVideo video={video} />
        <div
          css={(theme) => ({
            alignItems: 'center',
            background: theme.palette.backgroundGradient,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: theme.spacing(3),
            width: '100%',
          })}
        >
          <HomePageCloudApp advantages={advantages} />
          <HomePageReference references={references} personalReference={personalReference} />
          <HomePageSupportApps applications={applications} />
          <HomePageContactForm />
        </div>
        <Certifications certifications={certifications} />
      </Page>
    </MainLayout>
  );
};

export default HomePageContentDay;
