/*
 * !!! This is only example / skeleton of an remote resource.
 * !!! Have to create implementation.
 */
import { CancelablePromiseType } from '@sprinx/after-razzle';
import { HomepageData } from 'types/HomepageData';
import { SupportedLocale, SupportedRegion } from 'types/Supported';
import apiClient from './appApi';

export default {
  get: getResource,
};

function getResource(params: {
  locale: SupportedLocale;
  region: SupportedRegion;
}): CancelablePromiseType<HomepageData> {
  return apiClient.get<HomepageData, { locale: SupportedLocale; region: SupportedRegion }>(
    '/:region/:locale/home',
    params,
  );
}
