/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import AdvantageCardContainer from 'components/AdvantageCardContainer';
import Container from 'components/Container';
import React from 'react';
import { useIntl } from 'react-intl';
import { Advantage } from 'types/Advantage';

export interface HomePageCloudAppProps extends React.HTMLAttributes<HTMLDivElement> {
  advantages?: Advantage[];
}

const HomePageCloudApp = React.forwardRef<HTMLDivElement, HomePageCloudAppProps>(({ advantages, ...rest }, ref) => {
  const intl = useIntl();
  return (
    <ClassNames>
      {({ theme }) => (
        <Container maxWidth='lg' css={{ padding: 0 }}>
          <div
            ref={ref}
            {...rest}
            css={{
              '@media (min-width: 425px)': {},
              alignItems: 'stretch',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              marginTop: theme.spacing(1),
              position: 'relative',
              zIndex: 1,
            }}
          >
            <h2
              css={{
                ...theme.typography.h2,
                '@media(min-width: 960px)': {
                  paddingLeft: theme.spacing(2),
                  textAlign: 'left',
                },
              }}
            >
              {intl.formatMessage({
                defaultMessage: 'Proč bych měl mít firemní aplikace v cloudu?',
                id: 'homepage.advantage.title',
              })}
            </h2>
            {advantages && <AdvantageCardContainer advantages={advantages} />}
          </div>
        </Container>
      )}
    </ClassNames>
  );
});

export default HomePageCloudApp;
