/** @jsx jsx */
/** @jsxFrag */
import { ClassNames, jsx } from '@emotion/react';
import ButtonLink from 'components/ButtonLink';
import Container from 'components/Container';
import useLocalizationContext from 'hooks/useLocalizationContext';
import LogoPremium from 'icons/LogoPremium';
import React from 'react';
import { useIntl } from 'react-intl';
import { routeUrl } from 'routesBuilder';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CommercialLineProps extends React.HTMLAttributes<HTMLDivElement> {
  isDark?: boolean;
}

const CommercialLine: React.FC<CommercialLineProps> = ({ isDark }) => {
  const intl = useIntl();
  const { locale, region } = useLocalizationContext();
  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{
            background: theme.palette.primary.dark,
            color: theme.palette.common.white,
            display: 'flex',
            justifyContent: 'center',

            padding: theme.spacing(1),
            width: '100%',
          }}
        >
          <Container
            maxWidth='lg'
            css={{
              '@media(min-width: 425px)': { padding: theme.spacing(0, 0.5) },
              '@media(min-width: 600px)': { justifyContent: 'center', padding: theme.spacing(0, 3) },
              '@media(min-width: 960px)': { padding: theme.spacing(0, 4.5) },
              display: 'flex',
              justifyContent: 'space-between',
              padding: 0,
            }}
          >
            <LogoPremium
              css={{
                '@media(min-width: 425px)': {
                  height: 64,
                  width: 150,
                },
                height: 32,
                width: 100,
              }}
            />
            <Divider />
            <div
              css={{
                '@media(min-width: 600px)': { flexDirection: 'row', fontSize: 16 },

                display: 'flex',
                flexDirection: 'column',
                fontSize: 12,
                justifyContent: 'space-around',
                whiteSpace: 'nowrap',
              }}
            >
              <div css={{ alignSelf: 'center' }}>
                {intl.formatMessage({
                  defaultMessage: 'Ještě více pohodlí pro Vaší firmu',
                  id: 'premium.promo.text',
                })}
              </div>
              <Divider css={{ '@media(min-width: 600px)': { display: 'flex' }, display: 'none' }} />
              <ButtonLink
                to={routeUrl('premium', {
                  locale,
                  params: { locale: locale, region: region },
                })}
                css={{ '&:hover': { boxShadow: isDark ? theme.darkShadows : theme.shadows[6] }, alignSelf: 'center' }}
              >
                {intl.formatMessage({ defaultMessage: 'Mám zájem', id: 'premium.promo.cta' })}
              </ButtonLink>
            </div>
          </Container>
        </div>
      )}
    </ClassNames>
  );
};

export default CommercialLine;

const Divider: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <ClassNames>
      {({ theme, cx, css }) => (
        <div
          css={cx(
            css({
              '@media(min-width: 960px)': {
                margin: theme.spacing(0.5, 5),
              },
              alignSelf: 'stretch',
              borderLeft: '1px solid',
              borderLeftColor: theme.palette.common.white,
              margin: theme.spacing(0.5, 2),
              width: 1,
            }),
            className,
          )}
        />
      )}
    </ClassNames>
  );
};
