/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import DoubleRightArrow from 'icons/DoubleRightArrow';
import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import theme from 'theme';

export interface ButtonLinkProps {
  children: React.ReactNode;
  className?: string;
  color?: 'primary' | 'secondary' | 'default';
  isExternal?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  to?: string;
}

const bgOptions = {
  default: theme.palette.divider,
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
};

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ children, className, onClick, isExternal, to = '/', color = 'primary' }, ref) => {
    return (
      <ClassNames>
        {({ cx, theme, css }) => {
          const cssP = cx(
            css({
              '& > svg': {
                '@media(min-width: 425px)': {
                  height: 10,
                  width: 10,
                },
                fill: theme.palette.common.white,
                height: 6,
                marginLeft: theme.spacing(0.5),
                width: 6,
              },
              '&:hover': {
                boxShadow: theme.shadows[6],
              },
              '@media(min-width: 425px)': { fontSize: 14 },
              '@media(min-width: 600px)': { fontSize: 16, padding: theme.spacing(1, 1.5) },
              alignItems: 'center',
              background: bgOptions[color],
              borderRadius: 3,
              boxShadow: theme.shadows[3],
              color: color === 'default' ? theme.palette.text.primary : theme.palette.common.white,
              cursor: 'pointer',
              display: 'flex',
              fontSize: 10,
              justifyContent: 'center',
              padding: theme.spacing(0.5, 1.5),
              textDecoration: 'none',
              transition: 'all .2s ease',
            }),
            className,
          );
          return isExternal ? (
            <a href={to} css={cssP} target='_blank' rel='noreferrer'>
              {children}
              <DoubleRightArrow />
            </a>
          ) : (
            <Link to={to} ref={ref} css={cssP} onClick={onClick}>
              {children}
              <DoubleRightArrow />
            </Link>
          );
        }}
      </ClassNames>
    );
  },
);

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
