/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import Container from 'components/Container';
import VideoComponent from 'components/VideoComponent';
import React from 'react';
import { Video } from 'types/Video';

export interface HomePageVideoProps extends React.HTMLAttributes<HTMLDivElement> {
  video: Video;
}

const HomePageVideo: React.FC<HomePageVideoProps> = ({ video }) => {
  return video && video.active ? (
    <ClassNames>
      {({ theme }) => (
        <Container maxWidth='lg'>
          <div
            css={{
              '& iframe': {
                background: 'transparent',
                height: 180,
                width: 320,
              },
              '@media(min-width: 425px)': {
                '& iframe': {
                  height: 239,
                  width: 425,
                },
                height: 239,
              },
              '@media(min-width: 600px)': {
                '& iframe': {
                  height: 337.5,
                  width: 600,
                },
                height: 337.5,
              },

              background: 'white',
              height: 180,
              padding: theme.spacing(2, 0),
              width: '100%',
            }}
          >
            <VideoComponent stream={video.videoUrl} />
          </div>
        </Container>
      )}
    </ClassNames>
  ) : null;
};

export default HomePageVideo;
