/** @jsx jsx */
/** @jsxFrag */
import { jsx } from '@emotion/react';
import Container from 'components/Container';
import backgroundMobile from 'images/background-dark-mobile.webp';
import background from 'images/background-dark.webp';
import DarkLayoutHeader from 'layouts/DarkLayout/components/DarkLayoutHeader';
import React from 'react';
import { LayoutData } from 'types/LayoutData';
import HomePageIntroductionText from './HomePageIntroductionText';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HomePageIntroductionNightProps extends React.HTMLAttributes<HTMLDivElement>, LayoutData {}

const HomePageIntroductionNight = React.forwardRef<HTMLDivElement, HomePageIntroductionNightProps>(
  ({ contact }, ref) => {
    return (
      <div
        css={{
          '@media (min-width: 600px)': {
            backgroundImage: `url(${background})`,
          },
          backgroundImage: `url(${backgroundMobile})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <DarkLayoutHeader contact={contact} />
        <div
          ref={ref}
          css={{
            '@media (min-width: 425px)': {
              minHeight: 250,
            },
            '@media (min-width: 600px)': {},
            '@media (min-width: 960px)': { minHeight: 300 },
            display: 'flex',
            justifyContent: 'center',
            minHeight: 220,
            width: '100%',
          }}
        >
          <Container maxWidth='lg' css={{ padding: 0 }}>
            <HomePageIntroductionText css={(theme) => ({ color: theme.palette.common.white })} />
          </Container>
        </div>
      </div>
    );
  },
);

export default HomePageIntroductionNight;
