/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import ButtonLink from 'components/ButtonLink';
import useLocalizationContext from 'hooks/useLocalizationContext';
import { useIntl } from 'react-intl';
import { routeUrl } from 'routesBuilder';

const HomePageIntroductionText: React.FC<{ className?: string }> = ({ className }) => {
  const { locale, region } = useLocalizationContext();
  const intl = useIntl();
  return (
    <ClassNames>
      {({ theme, cx, css }) => (
        <div
          css={cx(
            css({
              '& > h1': { fontWeight: theme.typography.fontWeightLight, marginBottom: theme.spacing(3) },
              '@media (min-width: 425px)': {
                '& > h1': { marginBottom: theme.spacing(4) },
                maxWidth: 250,
              },
              '@media (min-width: 600px)': { maxWidth: 300 },
              '@media (min-width: 960px)': { maxWidth: 450 },
              alignItems: 'center',
              color: theme.palette.text.primary,
              display: 'flex',
              flexDirection: 'column',
              maxWidth: 220,
              padding: theme.spacing(3, 0),
            }),
            className,
          )}
        >
          <h1 css={{ ...theme.typography.h1, color: 'inherit' }}>
            {intl.formatMessage({
              defaultMessage: 'Nejpohodlnější hosting vašich aplikací',
              id: 'homepage.introduction.title',
            })}
          </h1>
          <ButtonLink to={routeUrl('applications', { locale, params: { locale: locale, region: region } })}>
            {intl.formatMessage({
              defaultMessage: 'Podporované aplikace',
              id: 'homepage.introduction.button',
            })}
          </ButtonLink>
        </div>
      )}
    </ClassNames>
  );
};

export default HomePageIntroductionText;
