/** @jsx jsx */
/** @jsxFrag */
import { ClassNames, jsx } from '@emotion/react';
import useLocalizationContext from 'hooks/useLocalizationContext';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { routeUrl } from 'routesBuilder';

export interface CheckboxProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  autoComplete?: string;
  error?: FieldError | undefined;
  label?: string;
  labelLink: string;
  name?: string;
  onChange?: (value: string | undefined) => void;
  placeholder?: string;
  value: boolean;
}

const GdprCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({ label, name, onChange, labelLink }, ref) => {
  const { locale, region } = useLocalizationContext();
  const handleChange = React.useCallback(
    (e) => {
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange],
  );
  return (
    <ClassNames>
      {({ theme }) => (
        <>
          <div>
            <input
              name={name}
              id={name}
              ref={ref}
              type='checkbox'
              css={{
                border: 'none',
                borderRadius: 3,
                color: theme.palette.text.primary,
                display: 'inline-block',
                float: 'left',
                fontFamily: theme.typography.fontFamily,
                fontSize: 14,
                marginRight: 10,
                marginTop: 6,
                outline: 'none',
                padding: theme.spacing(1.25, 2),
              }}
              onChange={handleChange}
            />
            <div css={{ fontSize: '13px', fontStyle: 'italic', marginTop: '5px', maxWidth: '360px' }}>
              <label htmlFor={name}>
                {label}
                <a target='_blank' href={routeUrl('gdpr', { locale, params: { locale, region } })} rel='noreferrer'>
                  {labelLink}
                </a>
              </label>
            </div>
          </div>
        </>
      )}
    </ClassNames>
  );
});

export default GdprCheckbox;
