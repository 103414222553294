/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import contactForm from 'api/contactForm';
import Button from 'components/Button';
import GdprCheckbox from 'components/GdprCheckbox';
import TextField from 'components/TextField';
import TextFieldMultiline from 'components/TextFieldMultiline';
import useLocalizationContext from 'hooks/useLocalizationContext';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactFormValues } from 'types/ContactFormValues';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export interface ContactFormProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  formPosition: string;
}

const ContactForm = React.forwardRef<HTMLDivElement, ContactFormProps>(({ className, formPosition }, ref) => {
  const intl = useIntl();
  const { locale, region } = useLocalizationContext();
  const [submitResult, setSubmitResult] = React.useState<{ success: boolean } | null>(null);

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    reset,
    watch,
  } = useForm();

  const sendData = React.useCallback(
    async (values: ContactFormValues) => {
      const reCaptchaResponse = 'xxx';
      if (typeof window !== undefined) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'formSubmission',
          formPosition,
          formType: 'Contact us',
        });
      }

      await contactForm
        .post({ ...values, locale, path: window.location.pathname, reCaptchaResponse, region })
        .catch((err) => {
          console.error('*** send form error', err);
          return { success: false };
        })
        .then(setSubmitResult); // eslint-disable-line no-console
    },
    [formPosition, locale, region, setSubmitResult],
  );
  const onSubmit = handleSubmit(sendData);

  React.useEffect(() => {
    if (submitResult && submitResult.success) {
      reset(
        {
          email: '',
          gdpr: false,
          message: '',
          name: '',
          phone: '',
        },
        {},
      );
    }
  }, [submitResult, reset]);

  return (
    <ClassNames>
      {({ cx, css, theme }) => (
        <div
          className={cx(
            css({
              padding: theme.spacing(1),
            }),
            className,
          )}
          ref={ref}
        >
          <div css={{ maxWidth: '400px' }}>
            {submitResult && submitResult.success && <FormattedMessage id={'contactform.formSent'} />}
            {submitResult && !submitResult.success && <FormattedMessage id={'contactform.formError'} />}
          </div>

          <form onSubmit={onSubmit} css={{ alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>
            <TextField
              name='name'
              ref={register({
                required: true,
              })}
              error={errors.name}
              label={intl.formatMessage({
                defaultMessage: 'Jméno',
                id: 'contactform.name',
              })}
              value={watch('name')}
            />
            <TextField
              name='phone'
              ref={register({
                pattern: {
                  message: intl.formatMessage({
                    defaultMessage: 'Zadejte prosím telefon v mezinárodním tvaru (+420 XXX XXX XXX)',
                    id: 'contactform.validation.phone',
                  }),
                  value:
                    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)([ ]*\d){1,14}$/,
                },
                required: true,
              })}
              error={errors.phone}
              label={intl.formatMessage({
                defaultMessage: 'Telefon',
                id: 'contactform.phone',
              })}
              value={watch('phone')}
            />
            <TextField
              type={'email'}
              name={'email'}
              ref={register({
                pattern: {
                  message: intl.formatMessage({
                    defaultMessage: 'Do e-mailové adresy zahrňte znak `@`. V adrese chybí znak `@`.',
                    id: 'contactform.validation.email',
                  }),
                  value: /^\S+@\S+\.\S+$|^$/,
                },
                required: true,
              })}
              error={errors.email}
              label={intl.formatMessage({
                defaultMessage: 'Email',
                id: 'contactform.mail',
              })}
              value={watch('email')}
            />
            <TextFieldMultiline
              ref={register({})}
              name='message'
              error={errors.message}
              label={intl.formatMessage({
                defaultMessage: 'Zpráva',
                id: 'contactform.message',
              })}
              value={watch('message')}
            />
            <GdprCheckbox
              ref={register({
                required: true,
              })}
              name='gdpr'
              error={errors.message}
              label={intl.formatMessage({
                defaultMessage:
                  'Souhlasím se zpracováním vyplněných údajů v rozsahu a k účelům vymezeným v Souhlasu se ',
                id: 'contactform.gdpr',
              })}
              labelLink={intl.formatMessage({
                defaultMessage: 'zpracováním osobních údajů',
                id: 'contactform.gdprLink',
              })}
              value={watch('gdpr')}
            />
            {errors.gdpr && (
              <p css={{ color: 'red' }}>
                <FormattedMessage id={'contactform.gdprError'} />
              </p>
            )}

            <Button
              disabled={isSubmitting}
              type='submit'
              css={(theme) => ({ alignSelf: 'flex-end', marginTop: theme.spacing(1), textTransform: 'capitalize' })}
            >
              <FormattedMessage id={'btn.send'} />
            </Button>
          </form>
        </div>
      )}
    </ClassNames>
  );
});

export default ContactForm;
