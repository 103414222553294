/** @jsx jsx */
/** @jsxFrag */
import { jsx } from '@emotion/react';
import Container from 'components/Container';
import uvodni from 'images/uvod.webp';
import React from 'react';
import theme from 'theme';
import HomePageIntroductionText from './HomePageIntroductionText';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HomePageIntroductionDayProps extends React.HTMLAttributes<HTMLDivElement> {
  //
}

const HomePageIntroductionDay = React.forwardRef<HTMLDivElement, HomePageIntroductionDayProps>(({ ...rest }, ref) => {
  return (
    <Container
      maxWidth='xl'
      css={{
        '@media(min-width: 600px)': { padding: 0 },
        '@media(min-width: 960px)': { padding: theme.spacing(0, 3) },
        '@media(min-width: 1180px)': {},
        flexDirection: 'column',
        padding: 0,
      }}
    >
      <div
        ref={ref}
        {...rest}
        css={{
          '@media (min-width: 425px)': {
            backgroundSize: 350,
            minHeight: 250,
          },
          '@media (min-width: 600px)': {
            backgroundImage: 'none',
            position: 'relative',
          },
          '@media (min-width: 960px)': { minHeight: 300 },
          backgroundImage: `url(${uvodni})`,
          backgroundPosition: 'right -40px bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 300,
          display: 'flex',
          justifyContent: 'center',
          minHeight: 220,
          width: '100%',
        }}
      >
        <Container maxWidth='lg' css={{ padding: 0 }}>
          <HomePageIntroductionText />
          <img
            src={uvodni}
            alt='uvodni'
            css={{
              '@media(min-width: 600px)': { display: 'block' },
              '@media(min-width: 960px)': {
                right: -90,
              },
              '@media(min-width: 1180px)': {
                width: 800,
              },
              bottom: 0,
              display: 'none',
              position: 'absolute',
              right: 0,
              width: '60%',
              zIndex: -1,
            }}
          />
        </Container>
      </div>
    </Container>
  );
});

export default HomePageIntroductionDay;
