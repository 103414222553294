/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import Container from 'components/Container';
import React from 'react';
import { Certification } from 'types/Certification';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CertificationsProps extends React.HTMLAttributes<HTMLDivElement> {
  certifications?: Certification[];
}

const Certifications = React.forwardRef<HTMLDivElement, CertificationsProps>(({ certifications = [] }, ref) => {
  return (
    <ClassNames>
      {({ theme }) => (
        <Container maxWidth='lg'>
          <div
            ref={ref}
            css={{
              '@media (min-width: 425px)': {},
              '@media (min-width: 600px)': { justifyContent: 'space-around' },
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'center',
              padding: theme.spacing(4, 0),
            }}
          >
            {certifications.map((certification, idx) => (
              <div
                key={idx.toString()}
                css={{
                  '& > img': { width: '100%' },
                  '@media (min-width: 960px)': { maxWidth: 'unset', width: theme.spacing(14) },
                  margin: theme.spacing(2),
                  maxWidth: theme.spacing(11),
                  width: 'calc(33% - 32px)',
                }}
              >
                <img src={certification.logo} alt={certification.title} title={certification.title} />
              </div>
            ))}
          </div>
        </Container>
      )}
    </ClassNames>
  );
});

export default Certifications;
