/** @jsx jsx */
/** @jsxFrag */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

export interface TextFieldMultilineProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  cols?: number;
  error?: string | undefined;
  label?: string;
  name: string;
  rows?: number;
  value: string | undefined;
}

const TextFieldMultiline = React.forwardRef<HTMLTextAreaElement, TextFieldMultilineProps>(
  ({ className, error, name, label, value, rows = 4, cols = 50 }, ref) => {
    return (
      <ClassNames>
        {({ cx, css, theme }) => (
          <div css={{ width: '100%' }}>
            <div
              className={cx(
                css({
                  '& > label': {
                    // background: value && value.length > 0 ? theme.palette.common.white : 'transparent',
                    background: 'transparent',
                    color: error
                      ? theme.palette.error.main
                      : value && value.length > 0
                      ? theme.palette.text.secondary
                      : theme.palette.text.secondary,
                    fontSize: value && value.length > 0 ? 10 : 14,
                    top: value && value.length > 0 ? '-8px' : '8px',
                  },
                  '& > textarea:focus + label': {
                    // background: theme.palette.common.white,
                    background: 'transparent',
                    color: error ? theme.palette.error.main : theme.palette.text.secondary,
                    fontSize: 10,
                    top: '-8px',
                  },
                  border: '1px solid',
                  borderColor: error ? theme.palette.error.main : 'transparent',
                  display: 'flex',
                  margin: theme.spacing(0.5, 0),
                  maxWidth: '100%',
                  position: 'relative',
                  width: '100%',
                }),
                className,
              )}
            >
              <textarea
                ref={ref}
                rows={rows}
                cols={cols}
                id={name}
                name={name}
                // value={value}
                css={{
                  '&::placeholder': {
                    color: theme.palette.text.secondary,
                    fontSize: 14,
                  },
                  border: 'none',
                  borderRadius: 3,
                  color: theme.palette.text.primary,
                  fontFamily: theme.typography.fontFamily,
                  fontSize: 14,
                  outline: 'none',
                  padding: theme.spacing(1.25, 2),
                  resize: 'none',
                  transition: 'all .2s ease',
                  width: '100%',
                }}
              />
              <label
                htmlFor={name}
                css={(theme) => ({
                  left: theme.spacing(1.5),
                  padding: theme.spacing(0, 0.5),
                  position: 'absolute',
                  transition: 'all .2s ease',
                })}
              >
                {label}
              </label>
            </div>
            {error && (
              <div css={{ color: theme.palette.error.main, marginBottom: theme.spacing(1), marginTop: '-8px' }}>
                {label} is required
              </div>
            )}
          </div>
        )}
      </ClassNames>
    );
  },
);

TextFieldMultiline.displayName = 'TextFieldMultiline';

export default TextFieldMultiline;
