import { CancelablePromiseType } from '@sprinx/after-razzle';
import { ContactFormValues } from 'types/ContactFormValues';
import { SupportedLocale, SupportedRegion } from 'types/Supported';
import apiClient from './appApi';

export default {
  post: postValues,
};

function postValues(
  params: ContactFormValues & {
    locale: SupportedLocale;
    path: string;
    reCaptchaResponse: string;
    region: SupportedRegion;
  },
): CancelablePromiseType<{ success: boolean }> {
  return apiClient.post('/contactForm', params, { timeout: 10 * 1000 });
}
