/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';
import YouTube, { Options } from 'react-youtube';

export interface VideoComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  stream: string;
  videoOpts?: Options;
}

const VideoComponent: React.FC<VideoComponentProps> = ({ className, stream, videoOpts }) => {
  return (
    <ClassNames>
      {({ cx, css }) => (
        <YouTube
          css={cx(
            css({
              '@media(max-width: 650px)': { height: 250, maxWidth: '100%' },
              alignItems: 'center',
              background: 'transparent',
              display: 'flex',
              height: 480,
              justifyContent: 'center',
              margin: '0 auto',
              maxWidth: 640,
            }),
            className,
          )}
          videoId={((s: string): string => {
            if (s.startsWith('http') && s.includes('youtube')) return new URL(s).searchParams.get('v') as string;
            if (s.startsWith('http')) return (s.match(/([^/]+)$/) || ['', ''])[1];
            return s;
          })(stream)}
          opts={{
            ...videoOpts,
            playerVars: {
              autoplay: 0,
              ...videoOpts?.playerVars,
            },
          }}
        />
      )}
    </ClassNames>
  );
};

VideoComponent.displayName = 'VideoComponent';
export default VideoComponent;
