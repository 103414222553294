/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

const Quote: React.FC<{}> = ({ ...rest }) => {
  return (
    <ClassNames>
      {() => (
        <svg xmlns='http://www.w3.org/2000/svg' width='113.368' height='82' viewBox='0 0 113.368 82' {...rest}>
          <g>
            <path
              fill='rgb(76,184,242)'
              fillRule='evenodd'
              d='M35.684202 0h23l-23 70.5-2.5 3-4 4-4.5 2.5-5.5 2h-5.5l-5-1-4.5-2.5s-1.26769764-.98922922-2.5-3c-1.23230237-2.01077078-1.08554552-1.48080908-1.5-3.5-.4144545-2.01919092 0-3 0-3l1.5-3 2.5-5 7.5-14 24-47z'
            />
            <path
              fill='rgb(76,184,242)'
              fillRule='evenodd'
              d='M90.36840399 0h23l-23 70.5-2.5 3-4 4-4.5 2.5-5.5 2h-5.5l-5-1-4.5-2.5s-1.26769763-.98922922-2.5-3-1.08554551-1.48080908-1.5-3.5 0-3 0-3l1.5-3 2.5-5 7.5-14 24-47z'
            />
          </g>
        </svg>
      )}
    </ClassNames>
  );
};

export default Quote;
