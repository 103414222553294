/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react';
import ApplicationCard from 'components/ApplicationCard';
import Container from 'components/Container';
import useLocalizationContext from 'hooks/useLocalizationContext';
import React from 'react';
import { useIntl } from 'react-intl';
import { routeUrl } from 'routesBuilder';
import { ApplicationTile } from 'types/Application';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HomePageSupportAppsProps extends React.HTMLAttributes<HTMLDivElement> {
  applications?: ApplicationTile[];
  isDark?: boolean;
}

const HomePageSupportApps = React.forwardRef<HTMLDivElement, HomePageSupportAppsProps>(
  ({ applications = [], isDark }, ref) => {
    const theme = useTheme();
    const { locale, region } = useLocalizationContext();
    const intl = useIntl();

    return (
      <Container maxWidth='lg' css={{ padding: 0 }}>
        <div
          ref={ref}
          css={{
            '@media (min-width: 425px)': {},
            alignItems: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginTop: theme.spacing(1),
          }}
        >
          <h2
            css={{
              ...theme.typography.h2,
              '@media(min-width: 960px)': {
                paddingLeft: theme.spacing(2),
                textAlign: 'left',
              },
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'Které aplikace podporujeme?',
              id: 'homepage.supportedapps.title',
            })}
          </h2>
          <div
            css={{
              '@media(min-width: 960px)': {
                padding: theme.spacing(1, 0),
              },
              alignSelf: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              maxWidth: theme.breakpoints.values.lg,
              padding: theme.spacing(1),
            }}
          >
            {applications.map((app, idx) => (
              <ApplicationCard
                isDark={isDark}
                application={app}
                key={idx.toString()}
                path={routeUrl('applicationDetail', {
                  locale,
                  params: { id: app.path, locale: locale, region: region },
                })}
              />
            ))}
          </div>
        </div>
      </Container>
    );
  },
);

export default HomePageSupportApps;
