/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ApplicationTile } from 'types/Application';

export interface ApplicationCardProps {
  application: ApplicationTile;
  className?: string;
  isDark?: boolean;
  path: string;
}

const ApplicationCard = React.forwardRef<HTMLAnchorElement, ApplicationCardProps>(
  ({ application, className, path, isDark }, ref) => {
    return (
      <ClassNames>
        {({ theme, cx, css }) => (
          <Link
            ref={ref}
            to={path}
            css={cx(
              css({
                '& > img': { maxHeight: '100%', maxWidth: '100%', width: 'auto' },
                '@media(min-width: 960px)': {
                  '&:active': {
                    transform: 'translate(0, 2px)',
                  },
                  '&:hover': {
                    boxShadow: isDark ? theme.darkShadows : theme.shadows[8],
                  },
                  height: `calc(200px - ${theme.spacing(2)})`,
                  transition: 'all .2s ease',
                  width: `calc(200px - ${theme.spacing(2)})`,
                },
                '@media(min-width: 1180px)': {
                  height: 250,
                  minWidth: 250,
                  width: 'calc(25% - 16px)',
                },
                alignItems: 'center',
                background: theme.palette.common.white,
                borderRadius: 3,
                display: 'flex',
                height: `calc(150px - ${theme.spacing(2)})`,
                justifyContent: 'center',
                margin: theme.spacing(1),
                padding: theme.spacing(1),
                width: `calc(150px - ${theme.spacing(2)})`,
              }),
              className,
            )}
          >
            <img src={application.logo} alt={application.title} title={application.title} />
          </Link>
        )}
      </ClassNames>
    );
  },
);

ApplicationCard.displayName = 'ApplicationCard';

export default ApplicationCard;
