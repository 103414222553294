/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import CommercialLine from 'components/CommercialLine';
import Page from 'components/Page';
import DarkLayout from 'layouts/DarkLayout';
import React from 'react';
import { HomepageData } from 'types/HomepageData';
import { SupportedLocale } from 'types/Supported';
import Certifications from '../../../components/Certifications/Certifications';
import HomePageCloudApp from './HomePageCloudApp';
import HomePageContactForm from './HomePageContactForm';
import HomePageIntroductionNight from './HomePageIntroductionNight';
import HomePageReference from './HomePageReference';
import HomePageSupportApps from './HomePageSupportApps';
import HomePageVideo from './HomePageVideo';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HomePageContentNightProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  data: HomepageData;
  locale: SupportedLocale;
}

const HomePageContentNight: React.FC<HomePageContentNightProps> = ({ data, className, locale }) => {
  const { advantages, layout, video, applications = [], certifications = [], personalReference, references } = data;

  return (
    <ClassNames>
      {({ theme, cx, css }) => (
        <DarkLayout {...layout} locale={locale}>
          <Page pageTitle={data.title} css={cx(css({ width: '100%' }), className)}>
            <HomePageIntroductionNight {...layout} />
            <CommercialLine isDark />
            <HomePageVideo video={video} />
            <div
              css={{
                alignItems: 'center',
                background: theme.palette.backgroundGradientDark,
                color: theme.palette.common.white,
                display: 'flex',
                flexDirection: 'column',
                paddingTop: theme.spacing(3),
                width: '100%',
              }}
            >
              <HomePageCloudApp advantages={advantages} />
              <HomePageReference personalReference={personalReference} references={references} css={{ opacity: 0.1 }} />
              <HomePageSupportApps applications={applications} isDark />
              <HomePageContactForm />
            </div>
            <Certifications certifications={certifications} />
          </Page>
        </DarkLayout>
      )}
    </ClassNames>
  );
};

export default HomePageContentNight;
