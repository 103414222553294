/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react';
import ContactForm from 'components/ContactForm';
import Container from 'components/Container';
import backgroundMobile from 'images/form-mobile.webp';
import background from 'images/form.webp';
import React from 'react';
import { useIntl } from 'react-intl';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HomePageContactFormProps extends React.HTMLAttributes<HTMLDivElement> {
  //
}

const HomePageContactForm = React.forwardRef<HTMLDivElement, HomePageContactFormProps>(({ ...rest }, ref) => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <Container
      maxWidth='xl'
      css={{
        '@media (min-width: 600px)': {
          padding: 0,
        },
        '@media (min-width: 960px)': {
          padding: theme.spacing(0, 3),
        },
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
      }}
    >
      <div
        ref={ref}
        {...rest}
        css={{
          '@media (min-width: 425px)': {},

          '@media (min-width: 960px)': {
            backgroundImage: `url(${background})`,
            backgroundPosition: 'right bottom 40px',
            backgroundSize: 400,
            paddingBottom: theme.spacing(4),
          },
          '@media (min-width: 1080px)': {
            backgroundSize: 600,
          },
          alignItems: 'center',
          backgroundImage: `url(${backgroundMobile})`,
          backgroundPosition: 'right -70px bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 320,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          marginTop: theme.spacing(2),
          paddingBottom: theme.spacing(22),
        }}
      >
        <Container
          maxWidth={1080}
          css={{
            '@media (min-width: 960px)': {
              alignItems: 'flex-start',
            },
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginTop: theme.spacing(1),
            padding: 0,
          }}
        >
          <h2 css={{ ...theme.typography.h2 }}>
            {intl.formatMessage({
              defaultMessage: 'Vyplatí se mi to?',
              id: 'homepage.form.title',
            })}
          </h2>
          <div css={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: theme.spacing(1) }}>
            <ContactForm formPosition='homepage' />
          </div>
        </Container>
      </div>
    </Container>
  );
});

export default HomePageContactForm;
