/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';
import Container from 'components/Container';
import LayoutHeaderAppbar from 'layouts/components/LayoutHeaderAppbar';
import LayoutNavigation from 'layouts/components/LayoutNavigation';
import React from 'react';
import theme from 'theme';
import { Contact } from 'types/ContactData';

export interface DarkLayoutHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  contact: Contact;
}

const DarkLayoutHeader: React.FC<DarkLayoutHeaderProps> = ({ contact }) => {
  return (
    <div
      css={(theme) => ({
        alignItems: 'center',
        color: theme.palette.header.textPrimary,
        display: 'flex',
        flexBasis: '100%',
        flexDirection: 'column',
        flexShrink: 0,
        justifyContent: 'flex-start',
      })}
    >
      <Container
        css={{
          '@media(min-width: 425px)': {
            '& > div > button > svg#Capa_1': { fill: '#FFF!important' },
            display: 'flex',
            justifyContent: 'flex-end',
          },
          display: 'none',
        }}
        maxWidth='xl'
      >
        <LayoutHeaderAppbar
          fSize={15}
          fWeight='bold'
          contact={contact}
          color={theme.palette.primary.main}
          linkColor={theme.palette.primary.main}
        />
      </Container>
      <Container css={{ justifyContent: 'center' }} maxWidth='lg'>
        <LayoutNavigation color={theme.palette.common.white} contact={contact} />
      </Container>
    </div>
  );
};

DarkLayoutHeader.displayName = 'DarkLayoutHeader';

export default DarkLayoutHeader;
