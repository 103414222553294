/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';

const LogoPremium: React.FC<{ className?: string; textColor?: string }> = ({ className, textColor = '#fff' }) => {
  return (
    <ClassNames>
      {({ cx, css, theme }) => (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='935.5'
          height='292.5'
          viewBox='0 0 935.5 292.5'
          css={cx(css(), className)}
        >
          <defs>
            <linearGradient
              id='linearGradient-4'
              x1='240.56'
              x2='208.94'
              y1='81.17'
              y2='179.45'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='rgb(116,192,67)' />
              <stop offset='1' stopColor='rgb(22,136,66)' />
            </linearGradient>
            <linearGradient
              id='linearGradient-6'
              x1='132.02'
              x2='210.8'
              y1='199.9'
              y2='231.06'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='rgb(4,108,58)' />
              <stop offset='1' stopColor='rgb(39,156,70)' />
            </linearGradient>
            <linearGradient
              id='linearGradient-8'
              x1='12.76'
              x2='56.76'
              y1='132.6'
              y2='132.6'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='rgb(101,188,70)' />
              <stop offset='1' stopColor='rgb(55,178,73)' />
            </linearGradient>
            <linearGradient
              id='linearGradient-12'
              x1='114.955'
              x2='48.98'
              y1='196.08'
              y2='224.16'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='rgb(21,135,66)' />
              <stop offset='1' stopColor='rgb(82,186,74)' />
            </linearGradient>
            <linearGradient
              id='linearGradient-15'
              x1='184.325'
              x2='156.65'
              y1='42.19'
              y2='82.18'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='rgb(145,200,67)' />
              <stop offset='1' stopColor='rgb(93,188,77)' />
            </linearGradient>
          </defs>
          <g>
            <text fontSize='0' style={{ whiteSpace: 'pre' }}>
              <tspan
                x='297.5'
                y='186.5'
                fontFamily='DaxlinePro'
                fontSize='116'
                fontWeight='500'
                style={{
                  fill: textColor,
                  fontFamily: theme.typography.fontFamily,
                  textTransform: 'none',
                  whiteSpace: 'pre',
                }}
                letterSpacing='-1.8'
              >
                AppOn
              </tspan>
            </text>
            <text fontSize='0' style={{ whiteSpace: 'pre' }}>
              <tspan
                x='638.5'
                y='186.5'
                fontFamily='DaxlinePro'
                fontSize='116'
                fontWeight='400'
                style={{
                  fill: textColor,
                  fontFamily: theme.typography.fontFamily,
                  textTransform: 'none',
                  whiteSpace: 'pre',
                }}
                letterSpacing='-1.2'
              >
                .cloud
              </tspan>
            </text>
            <path
              fill='rgb(212,176,101)'
              fillRule='evenodd'
              d='M672 214.5h254c1.65574507 0 3 1.34425493 3 3v60c0 1.65574507-1.34425493 3-3 3H672c-1.65574507 0-3-1.34425493-3-3v-60c0-1.65574507 1.34425493-3 3-3z'
            />
            <text fontSize='0' style={{ whiteSpace: 'pre' }}>
              <tspan
                x='682'
                y='266.5'
                fill='rgb(255,255,255)'
                fontFamily='DaxlinePro'
                fontSize='52'
                fontWeight='500'
                style={{
                  fill: textColor,
                  fontFamily: theme.typography.fontFamily,
                  textTransform: 'none',
                  whiteSpace: 'pre',
                }}
                letterSpacing='4.4'
              >
                Premium
              </tspan>
            </text>
            <path
              fill='rgb(255,255,255)'
              fillRule='evenodd'
              d='M130 0h-11.5L6 64l-6 9.5v130l5 9.5 113.5 64H130l111-64 8-9.5.5-130L241 64 130 0z'
            />
            <path
              fill='url(#linearGradient-4)'
              fillRule='evenodd'
              d='M237.5 80l-51 29.5s35.5 7 42.5 31-23.5 38-23.5 38l32 18.5V80z'
            />
            <path
              fill='url(#linearGradient-6)'
              fillRule='evenodd'
              d='M130 206v59.5L231 207l-39.5-23.5s-3.629 1.4185-9.5 2.5-9.5 1-9.5 1-9.15279963 9.87419277-19.5 14.5c-10.34720037 4.62580723-23 4.5-23 4.5z'
            />
            <path
              fill='url(#linearGradient-8)'
              fillRule='evenodd'
              d='M11 79.5l44 25s-33.4015 12.0525-38.5 36c-5.0985 23.9475 22 41.5 22 41.5L11 197.5v-118z'
            />
            <path
              fill='rgb(0,178,240)'
              fillRule='evenodd'
              d='M171 117.5l-41 24v53s8.567 1.062 20-4.5 17-13.5 17-13.5 52.5-4.5 52.5-28.5-48.5-30.5-48.5-30.5z'
            />
            <path
              fill='rgb(0,187,242)'
              fillRule='evenodd'
              d='M68 112.5l50.5 29v52s-4.51128991-.44597064-11-4-10-7.5-10-7.5S42 187.5 29 156s39-43.5 39-43.5z'
            />
            <path
              fill='url(#linearGradient-12)'
              fillRule='evenodd'
              d='M50 187.5L16.5 207 118 265.5v-61s-4.584-.101-12-3.5-12-7.5-12-7.5-8.904 1.854-22.5 0-21.5-6-21.5-6z'
            />
            <path
              fill='rgb(113,208,246)'
              fillRule='evenodd'
              d='M79.5 107l44.5 25.5 39.5-22.5s-8-26-39.5-27.5S79.5 107 79.5 107z'
            />
            <path
              fill='url(#linearGradient-15)'
              fillRule='evenodd'
              d='M130 11.5l102.5 59-59.5 34s-4.713-12.2665-18-22c-13.287-9.7335-25-9.5-25-9.5V11.5z'
            />
            <path
              fill='rgb(151,201,60)'
              fillRule='evenodd'
              d='M118.5 11l-102 59 54 31.5s5.168-12.539 20-21.5 28-7.5 28-7.5V11z'
            />
          </g>
        </svg>
      )}
    </ClassNames>
  );
};

export default LogoPremium;
