/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import AdvantageCard from 'components/AdvantageCard/AdvantageCard';
import React from 'react';
import Zoom from 'react-reveal/Zoom';
import { Advantage } from 'types/Advantage';

export interface AdvantageCardContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  advantages: Advantage[];
  className?: string;
}

const AdvantageCardContainer: React.FC<AdvantageCardContainerProps> = ({ advantages, className }) => {
  return (
    <ClassNames>
      {({ theme, cx, css }) => (
        <div
          css={cx(
            css({
              '& > div': {
                '&:nth-of-type(1)': { background: '#a0bc4d' },
                '&:nth-of-type(2)': { background: '#85b752' },
                '&:nth-of-type(3)': { background: '#509c51' },
                '&:nth-of-type(4)': { background: '#327242' },
                '@media(min-width: 600px)': {
                  maxWidth: 270,
                  width: 'calc(25% - 16px)',
                },
                '@media(min-width: 960px)': {
                  margin: theme.spacing(1),
                  padding: theme.spacing(3),
                },
                borderRadius: 3,
                margin: theme.spacing(0.5),
                padding: theme.spacing(2),
                width: 'calc(50% - 8px)',
              },
              '@media(min-width: 600px)': { justifyContent: 'center' },
              display: 'flex',
              flexWrap: 'wrap',
              padding: theme.spacing(1, 0),
            }),
            className,
          )}
        >
          {(advantages || []).map((advantage, idx) => (
            <Zoom key={idx.toString()}>
              <AdvantageCard advantage={advantage} />
            </Zoom>
          ))}
        </div>
      )}
    </ClassNames>
  );
};

AdvantageCardContainer.displayName = 'AdvantageCardContainer';

export default AdvantageCardContainer;
