/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import React from 'react';
import { Advantage } from 'types/Advantage';

const AdvantageCard: React.FC<{ advantage: Advantage; className?: string }> = ({ advantage, className }) => {
  return (
    <ClassNames>
      {({ theme, cx, css }) => (
        <div
          css={cx(
            css({
              ...theme.typography.body1,
              '@media(min-width: 425px)': {
                fontSize: 16,
              },
              color: theme.palette.common.white,
              display: 'flex',
              flexDirection: 'column',
              fontSize: 12,
            }),
            className,
          )}
        >
          <div>{advantage.title}</div>
          <div
            css={{
              '& > img': { maxHeight: '100%', maxWidth: '100%' },
              height: 90,
              margin: theme.spacing(3, 0),
              width: '100%',
            }}
          >
            {advantage.image && <img src={advantage.image} alt={advantage.title} />}
          </div>
          <div
            css={{
              ...theme.typography.caption,
              '@media(min-width: 960px)': { fontSize: 13 },
              alignItems: 'flex-end',
              display: 'flex',
              justifyContent: 'flex-start',
              minHeight: 72,
            }}
          >
            {advantage.text}
          </div>
        </div>
      )}
    </ClassNames>
  );
};

export default AdvantageCard;
