/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/react';
import Container from 'components/Container';
import ImageListCarousel from 'components/ImageListCarousel';
import ReferenceCard from 'components/ReferenceCard';
import useMediaQuery from 'hooks/useMediaQuery';
import bublina from 'images/bublina.webp';
import React from 'react';
import { CompanyReference, PersonalReference } from 'types/Reference';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HomePageReferenceProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  personalReference: PersonalReference;
  references: CompanyReference[];
}

const HomePageReference = React.forwardRef<HTMLDivElement, HomePageReferenceProps>(
  ({ className, personalReference, references = [], ...rest }, ref) => {
    return (
      <ClassNames>
        {({ theme, cx, css }) => (
          <Container
            maxWidth='lg'
            css={{
              '@media(min-width: 425px)': { padding: theme.spacing(5, 1.5) },
              '@media(min-width: 600px)': { padding: theme.spacing(5, 3, 10, 3) },
              padding: theme.spacing(5, 1),
            }}
          >
            <div
              ref={ref}
              {...rest}
              css={{
                '@media (min-width: 600px)': { minHeight: 400 },
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                maxWidth: '100%',
                minHeight: 350,
                position: 'relative',
              }}
            >
              <img
                src={bublina}
                alt='background'
                css={cx(
                  css({
                    '@media (min-width: 425px)': { height: '110%', width: '130%' },
                    '@media (min-width: 600px)': { height: '140%', width: '120%' },
                    left: '50%',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    zIndex: 0,
                  }),
                  className,
                )}
              />
              <HomePageReferenceCarousel references={references} />
              {personalReference && <ReferenceCard reference={personalReference} />}
            </div>
          </Container>
        )}
      </ClassNames>
    );
  },
);

export default HomePageReference;

const HomePageReferenceCarousel: React.FC<{ references: CompanyReference[] }> = ({ references }) => {
  const desktop = useMediaQuery('@media(min-width: 960px)');
  const mobile = useMediaQuery('@media(max-width: 600px)');
  return (
    <ClassNames>
      {({ theme }) => (
        <div
          css={{
            '@media(min-width: 600px)': {
              maxWidth: 1048,
            },
            alignSelf: 'center',
            background: theme.palette.common.white,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 3,
            minHeight: 40,
            paddingBottom: 20,
            paddingTop: 15,
            position: 'relative',
            width: '100%',
            zIndex: 1,
          }}
        >
          {references.length > 0 && (
            <ImageListCarousel cardCount={desktop ? 8 : mobile ? 2 : 4} autoSlide>
              {references.map((reference, idx) => (
                <div
                  key={idx.toString()}
                  css={{
                    '& > img': {
                      maxHeight: 60,
                      width: 60,
                    },
                    '@media(min-width: 600px)': {
                      '& > img': {
                        maxHeight: 80,
                        width: 80,
                      },
                    },
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img src={reference.logo} alt={reference.title} />
                </div>
              ))}
            </ImageListCarousel>
          )}
        </div>
      )}
    </ClassNames>
  );
};
