/** @jsx jsx */
import { jsx } from '@emotion/react';
import { AfterPage, AfterPageProps } from '@sprinx/after-razzle';
import homepageData from 'api/homepageData';
import cond from 'lodash/fp/cond';
import ErrorPage from 'pages/ErrorPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import LocalizationProvider from 'providers/LocalizationProvider';
import { HomepageData } from 'types/HomepageData';
import { SupportedLocale, SupportedRegion } from 'types/Supported';
import { routeUrl } from '../../routesBuilder';
import HomePageContentDay from './components/HomePageContentDay';
import HomePageContentNight from './components/HomePageContentNight';

export interface HomePageProps extends AfterPageProps<HomePageRouteParams> {
  data?: HomepageData;
  locale: SupportedLocale;
  night: boolean;
  region: SupportedRegion;
}

interface HomePageRouteParams {
  locale: SupportedLocale;
  region: SupportedRegion;
}

const HomePage: AfterPage<HomePageRouteParams, HomePageProps> = ({ data, night, error, locale, region }) => {
  if (error) return <ErrorPage locale={locale} region={region} error={error} />;
  if (!data) return <NotFoundPage locale={locale} region={region} />;

  return (
    <LocalizationProvider locale={locale} region={region}>
      {night ? (
        <HomePageContentNight
          data={data}
          locale={locale}
          css={{
            '& h2': { color: '#fff' },
          }}
        />
      ) : (
        <HomePageContentDay data={data} locale={locale} />
      )}
    </LocalizationProvider>
  );
};

HomePage.getInitialProps = async ({ match }) => {
  const { locale, region } = match.params;

  const night = cond<void, boolean>([
    [(): boolean => match.url === routeUrl('homeDay', { locale, params: { locale, region } }), (): boolean => false],
    [(): boolean => match.url === routeUrl('homeNight', { locale, params: { locale, region } }), (): boolean => true],
    [
      (): boolean => true,
      (): boolean => {
        const date = new Date();
        return date.getHours() > 19 || date.getHours() < 8;
      },
    ],
  ])();

  const data = await homepageData.get({ locale, region });

  return {
    data,
    locale,
    night,
    region,
  };
};

export default HomePage;
